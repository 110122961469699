import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
  .obs {
    font-size: 0.875rem;
    color: #ababab;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
`;

const PromotionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (min-width: 640px) {
    grid-gap: 20px;
  }
`;

const Title = styled.h1`
  color: #f1c524;
  font-family: 'Yellowtail', cursive;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
`;

const PromotionItem = styled.div`
  background-color: #f1c524;
  padding: 12px 2px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  .title {
    font-family: 'Raleway', 'sans-serif';
    font-size: 18px;
    font-weight: 900;
    -webkit-text-stroke: 1px #ffffff;
    color: #000000;
  }

  .desc {
    color: #000000;
  }

  .price {
    font-size: 18px;
    font-family: 'Raleway', 'sans-serif';
    font-weight: 900;
    color: red;
    -webkit-text-stroke: 1px #ffffff;
  }

  @media (min-width: 350px) {
    .title {
      font-size: 1.375rem;
    }

    .price {
      font-size: 1.375rem;
    }
  }
`;

const Promotion = () => (
  <Container>
    <Title>Promoção</Title>
    <PromotionWrapper>
      <PromotionItem>
        <p className="title">Combo 1</p>
        <p className="desc">10 Esfihas</p>
        <p className="price">46,00</p>
      </PromotionItem>

      <PromotionItem>
        <p className="title">Combo 2</p>
        <p className="desc">15 Esfihas</p>
        <p className="price">58,00</p>
      </PromotionItem>

      <PromotionItem>
        <p className="title">Combo 3</p>
        <p className="desc">20 Esfihas</p>
        <p className="price">69,00</p>
      </PromotionItem>
    </PromotionWrapper>

    <p className="obs">
      Sabores da Promoção: Carne, Queijo, Calabresa e Frango (máx. 3 esfihas de
      frango por combo).
    </p>
  </Container>
);

export default Promotion;
